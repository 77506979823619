import Vue from "vue";
import VueRouter from "vue-router";
import { authModule } from "./store/modules/auth";
import { organisationModule } from "./store/modules/OrganisationModule";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      component: () => import("./components/Login.vue"),
      meta: { requiresAuthentication: false },
    },
    {
      path: "/users",
      component: () => import("./components/Users.vue"),
      meta: { requiresAuthentication: true },
    },
    {
      path: "/users/add",
      component: () => import("./components/User.vue"),
      meta: { requiresAuthentication: true },
    },
    {
      path: "/users/:id",
      component: () => import("./components/User.vue"),
      meta: { requiresAuthentication: true },
    },
    {
      path: "/organisations",
      component: () => import("./components/Organisations.vue"),
      meta: { requiresAuthentication: true },
    },
    {
      path: "/organisations/add",
      component: () => import("./components/OrganisationAdd.vue"),
      meta: { requiresAuthentication: true },
    },
    {
      path: "/organisation",
      component: () => import("./components/OrganisationManage.vue"),
      meta: { requiresAuthentication: true, requiresOrganisation: true },
    },
    {
      path: "/organisation/units",
      component: () => import("./components/OrganisationUnits.vue"),
      meta: { requiresAuthentication: true, requiresOrganisation: true },
    },
    {
      path: "/organisation/units/import",
      component: () => import("./components/OrganisationUnitsImport.vue"),
      meta: { requiresAuthentication: true, requiresOrganisation: true },
    },
    {
      path: "/organisation/units/edit/:id",
      component: () => import("./components/OrganisationUnit.vue"),
      meta: { requiresAuthentication: true, requiresOrganisation: true },
    },
		{
			path: "/organisation/units/add",
      component: () => import("./components/OrganisationUnit.vue"),
      meta: { requiresAuthentication: true, requiresOrganisation: true }
		},
		{
			path: "/organisation/units/add/:parentId",
      component: () => import("./components/OrganisationUnit.vue"),
      meta: { requiresAuthentication: true, requiresOrganisation: true }
		},
    {
      path: "/conversionfactors",
      component: () => import("./components/ConversionFactors.vue"),
      meta: { requiresAuthentication: true, requiresOrganisation: true },
    },
    {
      path: "/manualemissions",
      component: () => import("./components/ManualEmissions.vue"),
      meta: { requiresAuthentication: true, requiresOrganisation: true },
    },
    {
      path: "/manualemissions/import",
      component: () => import("./components/ManualEmissionsImport.vue"),
      meta: { requiresAuthentication: true, requiresOrganisation: true },
    },
    {
      path: "/manualemission",
      component: () => import("./components/ManualEmission.vue"),
      meta: { requiresAuthentication: true, requiresOrganisation: true },
    },
    {
      path: "/imports",
      component: () => import("./components/ImportBatches.vue"),
      meta: { requiresAuthentication: true, requiresOrganisation: true },
    },
    {
      path: "/imports/:id",
      component: () => import("./components/ImportBatch.vue"),
      meta: { requiresAuthentication: true, requiresOrganisation: true },
    },
    {
      path: "/suppliers",
      component: () => import("./components/Suppliers.vue"),
      meta: { requiresAuthentication: true },
    },
    {
      path: "/invoices",
      component: () => import("./components/Invoices.vue"),
      meta: { requiresAuthentication: true },
    },
    {
      path: "/invoices/analyze",
      component: () => import("./components/Analyze.vue"),
      meta: { requiresAuthentication: true },
    },
    {
      path: "/invoices/:id",
      component: () => import("./components/Invoice.vue"),
      meta: { requiresAuthentication: true, requiresOrganisation: true },
    },
    {
      path: "/emissiontypes",
      component: () => import("./components/EmissionTypes.vue"),
      meta: { requiresAuthentication: true },
    },
    {
      path: "/distances",
      component: () => import("./components/Distances.vue"),
      meta: { requiresAuthentication: true },
    },
    {
      path: "/geoemissions/import",
      component: () => import("./components/GeoEmissionsImport.vue"),
      meta: { requiresAuthentication: true },
    },
    {
      path: "/reviews",
      component: () => import("./components/AirportAliasReview.vue"),
      meta: { requiresAuthentication: true },
    },
    {
      path: "/flights",
      component: () => import("./components/Flights.vue"),
      meta: { requiresAuthentication: true },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const requiresAuthentication = to.matched.some(
    (record) => record.meta.requiresAuthentication
  );

  if (requiresAuthentication && !authModule.isAuthenticated) {
    next("/");
  } else {
    const requiresOrganisation = to.matched.some(
      (record) => record.meta.requiresOrganisation
    );
    if (requiresOrganisation && !organisationModule.organisationDetails) {
      next("/organisations");
    } else {
      next();
    }
  }
});

export default router;
