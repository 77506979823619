




























































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { OrganisationDetails } from "./models/OrganisationDetails";
import { organisationModule } from "./store/modules/OrganisationModule";
import { authModule } from "./store/modules/auth";
import { Profile } from "./models/Profile";

@Component({})
export default class App extends Vue {
  private message: string = null;
  private messageType: string = null;

  get organisation(): OrganisationDetails {
    return organisationModule.organisationDetails;
  }

  get isAuthenticated(): boolean {
    return authModule.isAuthenticated;
  }

  private get profile(): Profile {
    return authModule.profile;
  }

  public async logout() {
    authModule.logout();
    this.$router.push("/");
  }
}
