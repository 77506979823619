import { OrganisationDetails } from "@/models/OrganisationDetails";
import { BaseService } from "./BaseService";
import { IConversionFactorType } from "@/models/ConversionFactorType";
import {
  IConversionFactor,
  IConversionFactorCreateRequest,
  IConversionFactorDeleteRequest,
} from "@/models/ConversionFactor";
import { OrganisationUnit } from "@/models/OrganisationUnit";
import {
  IAirport,
  IAirportAliasReview,
  IAirportCreateRequest,
  IAirportUpdateRequest,
} from "@/models/Airport";
import IFlight from "@/models/Flight";
import { IUser } from "@/models/IUser";
import { GeoArea } from "@/models/GeoArea";
import { OrganisationSettingsModel } from "@/models/OrganisationSettingsModel";
import { Organisation } from "@/models/Organisation";
import { CreateOrganisation } from "@/models/CreateOrganisation";
import { InvoiceSearchResultResponse } from "@/models/InvoiceSearchResultResponse";
import { InvoiceSearchRequest } from "@/models/InvoiceSearchRequest";

class AdminService extends BaseService {
  public async getManualEmissions(
    organisationId: string,
    filter: any = null
  ): Promise<any> {
    const response = await this.get(
      `internal/organisations/${organisationId}/manualemissions`,
      filter
    );
    return response.data;
  }

  public async getConversionFactors(
    organisationId: string,
    conversionFactorTypeId: string
  ): Promise<IConversionFactor[]> {
    const response = await this.get(
      `internal/organisations/${organisationId}/conversionfactors/${conversionFactorTypeId}`
    );
    return response.data;
  }

  public async getAllConversionFactors(
    organisationId: string
  ): Promise<IConversionFactor[]> {
    const response = await this.get(
      `internal/organisations/${organisationId}/conversionfactors`
    );
    return response.data;
  }

  public async getOrganisation(
    organisationId: string
  ): Promise<OrganisationDetails> {
    const response = await this.get(`internal/organisations/${organisationId}`);
    return response.data as OrganisationDetails;
  }

  public async createOrganisation(
    createOrganisation: CreateOrganisation,
  ): Promise<string> {
    const response = await this.post(
      `internal/organisations/`,
      createOrganisation,
    );
    return response.data;
  }

  public async saveOrganisation(
    organisationId: string,
    organisation: Organisation,
  ) {
    await this.put(
      `internal/organisations/${organisationId}`,
      organisation,
    );
  }

  public async getOrganisations(): Promise<Organisation[]> {
    const response = await this.get("internal/organisations");
    return response.data;
  }

  public async getOrganisationSettings(
    organisationId: string,
  ): Promise<OrganisationSettingsModel> {
    const response = await this.get(`internal/organisation/settings/${organisationId}`);
    return response.data as OrganisationSettingsModel;
  }

  public async saveOrganisationSettings(
    organisationId: string,
    settings: OrganisationSettingsModel,
  ) {
    await this.post(
      `internal/organisation/settings/${organisationId}`,
      settings,
    );
  }

  public async getImports(organisationId: string): Promise<any> {
    const response = await this.get(
      `internal/organisations/${organisationId}/imports`
    );
    return response.data;
  }

  public async getImport(
    organisationId: string,
    importId: string
  ): Promise<any> {
    const response = await this.get(
      `internal/organisations/${organisationId}/imports/${importId}`
    );
    return response.data;
  }

  public async deleteImport(organisationId: string, id: string): Promise<any> {
    const response = await this.delete(
      `internal/organisations/${organisationId}/imports/${id}`
    );
    return response.data;
  }

  public async reImport(organisationId: string, id: string): Promise<any> {
    const response = await this.put(
      `internal/organisations/${organisationId}/imports/${id}`
    );
    return response.data;
  }

  public async getInvoiceUrl(
    organisationId: string,
    invoiceId: string,
    type: string
  ): Promise<any> {
    const response = await this.get(
      `internal/organisations/${organisationId}/invoices/${invoiceId}/url?type=${type}`
    );
    return response.data;
  }

  public async getSuppliers(onlyActive: boolean, orgId: string): Promise<any> {
    var url = `internal/suppliers?onlyActive=${onlyActive}`;
    if (orgId) url += `&orgId=${orgId}`;

    const response = await this.get(url);
    return response.data;
  }

  public async getEmissionTypes(): Promise<any> {
    const response = await this.get("internal/emissiontypes");
    return response.data;
  }

  public async getDistances(): Promise<any> {
    const response = await this.get("internal/distances");
    return response.data;
  }

  public async findInvoices(
    organisationId: string,
    filter: InvoiceSearchRequest,
  ): Promise<InvoiceSearchResultResponse> {
    const response = await this.post(
      `internal/organisations/${organisationId}/invoices`,
      filter,
    );
    return response.data;
  }

  public async createManualEmission(organisationId: string, emission: any) {
    await this.post(
      `internal/organisations/${organisationId}/manualemissions`,
      emission
    );
  }

  public async importManualEmissions(organisationId: string, batch: any) {
    await this.post(
      `internal/organisations/${organisationId}/manualemissions/import`,
      batch
    );
  }

  public async getConversionFactorTypes(): Promise<IConversionFactorType[]> {
    const response = await this.get("internal/conversionfactortypes");
    return response.data;
  }

  public async createConversionFactor(
    organisationId: string,
    factor: IConversionFactorCreateRequest
  ) {
    await this.post(
      `internal/organisations/${organisationId}/conversionfactors`,
      factor
    );
  }

  public async deleteConversionFactor(
    organisationId: string,
    factor: IConversionFactorDeleteRequest
  ) {
    await this.delete(
      `internal/organisations/${organisationId}/conversionfactors/${factor.emissionTypeId}/${factor.conversionFactorTypeId}/${factor.fromYear}/${factor.fromMonth}`
    );
  }

  public async aggregate(organisationId: string) {
    await this.put(`internal/organisations/${organisationId}/emissions`);
  }

  public async analyze(
    organisationId: string,
    supplierId?: string,
    fromInvoiceDate?: string
  ) {
    await this.post(
      `internal/organisations/${organisationId}/invoices/emissions`,
      { supplierId: supplierId, fromInvoiceDate: fromInvoiceDate }
    );
  }

  public async getInvoiceDetails(
    organisationId: string,
    invoiceId: string
  ): Promise<any> {
    const response = await this.get(
      `internal/organisations/${organisationId}/invoices/${invoiceId}`
    );
    return response.data;
  }

  public async getGeoAreas(): Promise<GeoArea[]> {
    const response = await this.get(`internal/geoarea`);
    return response.data;
  }

  public async importGeoAreaEmissions(data: FormData) {
    await this.uploadFile("internal/geoarea/emissions/import", data);
  }

  public async importGeoAreaEmissionsUpdate(data: FormData) {
    await this.uploadFile("internal/geoarea/emissions/importupdate", data);
  }

  public async importGeoAreaPopulation(data: FormData) {
    await this.uploadFile("internal/geoarea/population/import", data);
  }

  public async importGeoAreaGrp(data: FormData) {
    await this.uploadFile("internal/geoarea/grp/import", data);
  }

  public async aggregateGeo() {
    await this.get(`internal/geoarea/aggregate`);
  }

  public async getOrganisationUnits(
    organisationId: string,
  ): Promise<OrganisationUnit[]> {
    const response = await this.get(
      `internal/organisationunits/${organisationId}/all`
    );
    return response.data;
  }

  public async getOrganisationUnit(
    organisationUnitId: string,
  ): Promise<OrganisationUnit> {
    const response = await this.get(
      `internal/organisationunits/${organisationUnitId}`
    );
    return response.data;
  }

  public async addOrganisationUnit(
    organisationId: string,
    organisationUnit: OrganisationUnit,
  ): Promise<string> {
    const response = await this.post(
      `internal/organisationunits/${organisationId}`,
      organisationUnit,
    );
    return response.data;
  }

  public async updateOrganisationUnit(
    organisationId: string,
    organisationUnit: OrganisationUnit,
  ) {
    await this.put(
      `internal/organisationunits/${organisationId}`,
      organisationUnit,
    );
  }

  public async deleteOrganisationUnit(
    organisationUnitId: string,
  ) {
    await this.delete(
      `internal/organisationunits/${organisationUnitId}`,
    );
  }

  public async getAirports(): Promise<IAirport[]> {
    try {
      const response = await this.get("internal/airports");
      if (response.status === 200) return response.data;
    } catch (error) {
      console.error(`Request getAirports failed with error ${error}`);
    }
  }

  public async addAirport(airport: IAirportCreateRequest): Promise<IAirport> {
    try {
      const response = await this.post(`internal/airports`, airport);
      if (response.status === 201) return response.data;
    } catch (error) {
      console.error(`Request addAirport failed with error ${error}`);
      throw error;
    }
  }

  public async updateAirport(
    airportId: string,
    airport: IAirportUpdateRequest
  ): Promise<IAirport> {
    try {
      const response = await this.patch(
        `internal/airports/${airportId}`,
        airport
      );
      if (response.status === 200) return response.data;
    } catch (error) {
      console.error(`Request addAirport failed with error ${error}`);
      throw error;
    }
  }

  public async deleteAirport(airportId: string) {
    try {
      const response = await this.delete(`internal/airports/${airportId}`);
      return response.status === 204;
    } catch (error) {
      console.error(`Request deleteAirport failed with error ${error}`);
    }
  }

  public async addAirportAlias(
    airportId: string,
    airportAliasId: string
  ): Promise<boolean> {
    try {
      const response = await this.put(
        `internal/airports/${airportId}/aliases`,
        { airportAliasId }
      );

      return response.status === 204;
    } catch (error) {
      console.error(`Request addAliasToAirport failed with error ${error}`);
    }
  }

  public async deleteAirportAlias(
    airportId: string,
    airportAliasId: string
  ): Promise<boolean> {
    try {
      const response = await this.delete(
        `internal/airports/${airportId}/aliases/${airportAliasId}`
      );

      return response.status === 204;
    } catch (error) {
      console.error(
        `Request deleteAliasFromAirport failed with error ${error}`
      );
    }
  }

  public async getAirportAliasReviews(): Promise<IAirportAliasReview[]> {
    try {
      const response = await this.get(`internal/airports/reviews`);
      if (response.status === 200) return response.data;
    } catch (error) {
      console.error(
        `Request getAirportAliasReviews failed with error ${error}`
      );
    }
  }

  public async getFlights(): Promise<IFlight[]> {
    const response = await this.get("internal/flights");
    return response.data;
  }

  public async getAllUsers(): Promise<IUser[]> {
    const response = await this.get(
      `internal/users`
    );
    return response.data;
  }

  public async getUser(
    userId: string,
  ): Promise<IUser> {
    const response = await this.get(
      `internal/users/${userId}`
    );
    return response.data;
  }

  public async createUser(
    user: IUser,
  ): Promise<string> {
    const response = await this.post(
      `internal/users`,
      user,
    );
    return response.data;
  }

  public async updateUser(
    user: IUser,
  ) {
    await this.put(
      `internal/users`,
      user,
    );
  }
}

const service: AdminService = new AdminService();

export default service;
